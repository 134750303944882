import axios from "axios";
import VueCookies from 'vue-cookies'
import qs from 'qs'

const ENDPOINT_PATH = 'https://api.travipet.com/api/v1/'

export default {

    setUserLogged(userLogged) {
        VueCookies.set('user', userLogged);
    },
    setSettings(settings) {
        VueCookies.set('settings', settings);
    },
    setPersonType(person_type) {
        VueCookies.set('person_type', person_type);
    },
    setPhoto(photo) {
        VueCookies.set('photo', photo);
    },
    setPersonName(name) {
        VueCookies.set('name', name);
    },
    setToken(loggin_token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${loggin_token}`;
        VueCookies.set('token', loggin_token);
    },
    setRefreshToken(refresh_token) {
        VueCookies.set('refresh_token', refresh_token);
    },
    getUserLogged() {
        return VueCookies.get('user');
    },
    getSettings() {
        return VueCookies.get('settings');
    },
    getPersonType() {
        return VueCookies.get('person_type');
    },
    getPersonName() {
        return VueCookies.get('name');
    },
    getPhoto() {
        return VueCookies.get('photo');
    },
    getToken() {
        return VueCookies.get('token');
    },
    getRefreshToken() {
        return VueCookies.get('refresh_token');
    },
    register(email, password) {
        const user = { email, password };
        return axios.post(ENDPOINT_PATH + "signup", user);
    },
    async login(form) {
        
        return await axios.post(ENDPOINT_PATH + "login", qs.stringify(form))
    },
    async reset_pass(form) {
        return await axios.post(ENDPOINT_PATH + "reset_request", qs.stringify(form))
    },
    removeCookies() {
        VueCookies.remove('token');
        VueCookies.remove('user');
        VueCookies.remove('person_type');
        VueCookies.remove('refresh_token');
        VueCookies.remove('name');
        VueCookies.remove('photo');
        VueCookies.remove('settings');
    },
    async logout() {
        
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.getToken()}`;
        try {
            const response = await axios.post(ENDPOINT_PATH + "logout");
            if (response.status === 200) {
                this.removeCookies();
            }
            return response;
        } catch (error) {
            console.log(error);
            return error;
        }
        }
    }
