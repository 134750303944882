<template>
  
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container d-none d-lg-flex flex-column mb-n2">
      <div class="d-flex justify-content-between align-items-center w-100">
              <!-- Logo -->
      <a class="navbar-brand" href="/">
        <img src="@/assets/img/travipet_logo.png" alt="TraviPet logo">
      </a>

      <!-- Navigation Links for Large Screens -->
      <ul class="navbar-nav flex-row align-items-center">
        <li class="nav-item" v-if="personType === 'Driver'">
          <a class="nav-link" @click="$router.push('/driver/requests')">
            <img src="@/assets/img/request.png" class="nav-icon"> Solicitudes
          </a>
        </li>
        <li class="nav-item" v-if="personType === 'Driver'">
          <a class="nav-link" @click="$router.push('/driver/travels')">
            <img src="@/assets/img/camino1.png" class="nav-icon"> Viajes
          </a>
        </li>
        <li class="nav-item" v-if="personType === 'Driver'">
          <a class="nav-link" @click="$router.push('/driver/cars')">
            <img src="@/assets/img/coche_bn.png" class="nav-icon"> Coches
          </a>
        </li>
        <li class="nav-item" v-if="personType === 'User'">
          <a class="nav-link" @click="$router.push('/user/requests')">
            <img src="@/assets/img/request.png" class="nav-icon"> Solicitudes
          </a>
        </li>
        <li class="nav-item" v-if="personType === 'User'">
          <a class="nav-link" @click="$router.push('/user/travels')">
            <img src="@/assets/img/camino1.png" class="nav-icon"> Viajes
          </a>
        </li>
        <li class="nav-item" v-if="personType === 'User'">
          <a class="nav-link" @click="$router.push('/pets')">
            <img src="@/assets/img/perros.png" class="nav-icon"> Mascotas
          </a>
        </li>
        <li class="nav-item dropdown" v-if="userLogged">
          <a class="nav-link d-flex align-items-center" href="#" id="notificationDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="notification-count" v-if="unreadCount">{{ unreadCount }}</span>
            <img src="@/assets/img/notificacion.png" class="nav-icon">
          </a>

          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="notificationDropdown">
            <button class="dropdown-item" @click="markAllAsRead">Marcar todas como leídas</button>
            <div v-if="notifications.length === 0" class="dropdown-item">No hay nuevas notificaciones</div>
            <div v-for="notification in notifications" :key="notification.id" class="dropdown-item">
              <a :href="notification.url" @click.prevent="handleNotificationClick(notification)">
                <h4>{{ notification.title }}</h4>
                <p>{{ notification.body }}</p>
              </a>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown" v-if="userLogged">
          <a class="nav-link dropdown-toggle d-flex align-items-center" href="#" id="userDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img @error="onImageError" :src="'https://d2kp1vykair26s.cloudfront.net/' + personPhoto" class="avatar rounded-circle">
            <span class="text-sm">
              <span class="small text-muted">{{ personType === 'User' ? 'Usuario' : 'Conductor' }}</span>
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
            <a class="dropdown-item" @click="$router.push(personType === 'Driver' ? '/driver/info' : '/user/info')">
              <img src="@/assets/img/user_bn.png" class="nav-icon"> Perfil
            </a>
            <!-- <a class="dropdown-item" @click="$router.push('/settings')">
              <img src="@/assets/img/preferencias.png" class="nav-icon"> Preferencias
            </a> -->
            <a class="dropdown-item"
              @click="$router.push(personType === 'Driver' ? '/driver/payments' : '/user/charges')">
              <img src="@/assets/img/cuenta.png" class="nav-icon"> Cuenta
            </a>
            <a class="dropdown-item" @click="userlogout()">
              <img src="@/assets/img/salida.png" class="nav-icon"> Logout
            </a>
          </div>
        </li>
      </ul>

      <!-- Login/Register Links for Large Screens -->
      <div v-if="!userLogged" class="d-flex">
        <a class="btn btn-link mb-2" href="/login">Login</a>
        <a class="btn btn-link mb-2" href="/register">Regístrate</a>
      </div>
      </div>
      <div class="w-100">
        <div class="alert alert-info marquee-container" v-if="current_travels">
          <div class="marquee">
            {{ this.current_travels }}
          </div>
        </div>
      </div>
      </div>
    

    <!-- Footer Navigation for Small Screens -->
    <div :class="['footer-navbar', 'd-lg-none', 'fixed-bottom', 'bg-light', 'shadow-lg', 'mt-0', 'mb-n2', { 'footer-navbar-expanded': !userLogged }]">
      <div class="alert alert-info marquee-container mb-0" v-if="current_travels">
        <div class="marquee text-sm">
          {{ this.current_travels }}
        </div>
      </div>
      <ul class="nav justify-content-around align-items-center">
        <!-- Logo -->
        <li class="nav-item">
          <a class="navbar-brand" href="/">
            <img src="@/assets/img/travipet_logo.png" alt="TraviPet logo" class="img-fluid" style="height: 35px;">
          </a>
        </li>

        <!-- Solicitudes -->
        <li class="nav-item">
          <a class="nav-link text-center" v-if="personType === 'Driver'" @click="$router.push('/driver/requests')">
            <img src="@/assets/img/request.png" class="nav-icon d-block mx-auto mb-1" style="height: 24px;">
            <span class="small">Solicitudes</span>
          </a>
          <a class="nav-link text-center" v-if="personType === 'User'" @click="$router.push('/user/requests')">
            <img src="@/assets/img/request.png" class="nav-icon d-block mx-auto mb-1" style="height: 24px;">
            <span class="small">Solicitudes</span>
          </a>
        </li>

        <!-- Viajes -->
        <li class="nav-item">
          <a class="nav-link text-center" v-if="personType === 'Driver'" @click="$router.push('/driver/travels')">
            <img src="@/assets/img/camino1.png" class="nav-icon d-block mx-auto mb-1" style="height: 24px;">
            <span class="small">Viajes</span>
          </a>
          <a class="nav-link text-center" v-if="personType === 'User'" @click="$router.push('/user/travels')">
            <img src="@/assets/img/camino1.png" class="nav-icon d-block mx-auto mb-1" style="height: 24px;">
            <span class="small">Viajes</span>
          </a>
        </li>

        <!-- Mascotas (solo para usuarios) -->
        <li class="nav-item" v-if="personType === 'User'">
          <a class="nav-link text-center" @click="$router.push('/pets')">
            <img src="@/assets/img/perros.png" class="nav-icon d-block mx-auto mb-1" style="height: 24px;">
            <span class="small">Mascotas</span>
          </a>
        </li>

        <!-- Coches (solo para conductores) -->
        <li class="nav-item" v-if="personType === 'Driver'">
          <a class="nav-link text-center" @click="$router.push('/driver/cars')">
            <img src="@/assets/img/coche_bn.png" class="nav-icon d-block mx-auto mb-1" style="height: 24px;">
            <span class="small">Coches</span>
          </a>
        </li>

        <!-- Notificaciones -->
        <li class="nav-item dropdown" v-if="userLogged">
          <a class="nav-link text-center d-flex align-items-center" href="#" id="notificationDropdownSmall" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="badge badge-danger notification-count" v-if="unreadCount">{{ unreadCount }}</span>
            <img src="@/assets/img/notificacion.png" class="nav-icon ml-1" style="height: 24px;">
          </a>
          <div class="dropdown-menu dropdown-menu-right p-3 shadow-lg" aria-labelledby="notificationDropdownSmall"
              style="max-width: 90vw; min-width: 300px; max-height: 70vh; overflow-y: auto;">
            <div class="container">
              <div class="row mb-2">
                <div class="col-12 d-flex justify-content-between align-items-center">
                  <span v-if="sortedNotifications.length === 0" class="text-muted">No hay nuevas notificaciones</span>
                  <button v-else class="btn btn-link p-0 text-right text-primary" @click="markAllAsRead">Marcar como leídas</button>
                </div>
              </div>
              <div v-for="notification in sortedNotifications.slice(0, 4)" :key="notification.id" class="dropdown-item p-2 border-bottom">
                <a href="#" @click.prevent="handleNotificationClick(notification)">
                <div class="row">
                  <div class="col-12 d-flex justify-content-between">
                    <h6 class="text-sm mb-0 font-weight-bold">{{ notification.title }}</h6>
                    <span class="text-xs text-muted">{{ timeSince(notification.created_at) }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <p class="notification-body text-xs text-muted mt-1 mb-0">{{ notification.body }}</p>
                  </div>
                </div>
                </a>
              </div>
            </div>
          </div>
        </li>

        <!-- Perfil de Usuario -->
        <div v-if="!userLogged" class="d-flex">
            <a class="btn btn-link mb-2" href="/login">Login</a>
            <a class="btn btn-link mb-2" href="/register">Regístrate</a>
        </div>
        <li v-else class="nav-item dropdown">
          <button class="btn btn-link text-center" @click="toggleDropdown">
            <span v-if="userLogged" class="text-sm">
            <img @error="onImageError" :src="'https://d2kp1vykair26s.cloudfront.net/' + personPhoto" class="avatar rounded-circle">
            <br>
            <span class="small text-muted">{{ personType === 'User' ? 'Usuario' : 'Conductor' }}</span>
            </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right" :class="{ show: dropdownOpen }">
            <!-- <a v-if="userLogged" class="dropdown-item text-muted" @click="$router.push('/settings')">
              <img src="@/assets/img/preferencias.png" class="nav-icon"> Preferencias
            </a> -->
            <a v-if="userLogged" class="dropdown-item text-muted"
              @click="$router.push(personType === 'Driver' ? '/driver/info' : '/user/info')">
              <img src="@/assets/img/user_bn.png" class="nav-icon"> Perfil
            </a>
            <a v-if="userLogged" class="dropdown-item text-muted"
              @click="$router.push(personType === 'Driver' ? '/driver/payments' : '/user/charges')">
              <img src="@/assets/img/cuenta.png" class="nav-icon"> Cuenta
            </a>
            <a v-if="userLogged" class="dropdown-item text-muted" @click="userlogout()">
              <img src="@/assets/img/salida.png" class="nav-icon"> Logout
            </a>
          </div>
        </li>
      </ul>
    </div>

  </nav>
</template>

<script>
import auth from "@/logic/auth";
import { ShowAlert } from "@/logic/aux";
import req from "@/logic/req";
import moment from 'moment';

export default {
  name: 'Headers',
  data() {
    return {
      dropdownOpen: false,
      notifications: [],
      unreadCount: 0,
      current_travels: '',
      dropdownNotificationOpen: false,
      pollingInterval: null,
    };
  },
  computed: {
    userLogged() {
      return auth.getUserLogged();
    },
    personType() {
      return auth.getPersonType();
    },
    personName() {
      return auth.getPersonName();
    },
    personPhoto() {
      return auth.getPhoto();
    },
    sortedNotifications() {
      return this.notifications.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }
  },
  methods: {
    ShowAlert,
       onImageError(e) {
      e.target.src = require('@/assets/img/user.png')
    },
    timeSince(date) {
      const now = new Date();
      const seconds = Math.floor((now - new Date(date)) / 1000);
      let interval = Math.floor(seconds / 31536000);

      if (interval > 1) {
        return interval + " años";
      }
      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return interval + " meses";
      }
      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return interval + " d";
      }
      interval = Math.floor(seconds / 3600);
      if (interval > 1) {
        return interval + " h";
      }
      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        return interval + " min";
      }
      return Math.floor(seconds) + " seg";
    },
    async getNotifications(){
      req.get('unread').then((res) => {
        this.notifications = res.data.notifications;
        this.current_travels = res.data.current_travels;
        const old_unread = this.unreadCount;
        this.unreadCount = this.notifications.length;
        if (this.unreadCount > old_unread) {
          console.log('New notifications: ' + this.unreadCount);
        }
        }).catch((error) => {
          console.log(error);
        });
    },
    toggleNotificationDropdown() {
      this.dropdownNotificationOpen = !this.dropdownNotificationOpen;
    },
    markAsRead(notificationIds){
      req.post('mark-as-read', {notificationIds})
        .then((res) => {
          console.log(res.data);
          this.notifications = this.notifications.filter(n => !notificationIds.includes(n.id));
        }).catch((error) => {
          console.log(error);
        });
    },
    async handleNotificationClick(notification) {
      this.markAsRead([notification.id]);
      this.notifications = this.notifications.filter(n => n.id !== notification.id); 
      window.location.href = notification.url;
    },
    async markAllAsRead() {
      const notificationIds = this.notifications.map(n => n.id);
      this.markAsRead(notificationIds);
    },
    formatDate(value) {
      return moment(String(value)).format('YY MMM DD');
    },
    userlogout() {
      auth.logout()
        .then((res) => {
          this.ShowAlert(
            ' Gracias por usar TraviPet. Hasta la próxima',
            '',
            res.status,
            '/'
          );
        }).catch((error) => {
          this.ShowAlert(
            'Error',
            error,
            error.status
          );
        });
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    }
  },
  mounted(){
    if (auth.getUserLogged()) {
      this.getNotifications(); // Obtener notificaciones al montar el componente
      this.pollingInterval = setInterval(this.getNotifications, 60000); // Polling cada 30 segundos
    }
  },
  beforeDestroy() {
    clearInterval(this.pollingInterval); // Limpiar el intervalo al destruir el componente
  }
}
</script>

<style scoped>
.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  height: 20px; /* Ajusta la altura según sea necesario */
  line-height: 20px; /* Alinea el texto verticalmente */
  padding: 0; /* Elimina el padding para reducir la altura */
}

.marquee {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.navbar {
  padding: 0rem 0;
}

.navbar-brand img {
  height: 2em;
}

.navbar-nav .nav-item {
  margin-left: 15px;
}

.nav-icon {
  margin-right: 0.5em;
  height: 1.5em;
}

.avatar {
  width: 3em;
  height: 3em;
  border-radius: 10%;
}

.d-lg-none {
  display: none !important;
}

.footer-navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-top: 1px solid #ddd;
  z-index: 1030;
}

.footer-navbar .nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5em 0;
}

.footer-navbar .nav-icon {
  height: 1.5em;
}

.dropdown-menu {
  position: absolute;
  transform: translate3d(0, 0, 0);
  will-change: transform;
  top: 100%;
  left: auto;
  right: 0;
  margin-top: 0.5rem;
}

.show .dropdown-menu {
  display: block;
}

@media (max-width: 991.98px) {
  .d-lg-none {
    display: block !important;
  }

  .d-none.d-lg-flex {
    display: none !important;
  }

  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 0.5rem;
  }

  .navbar {
    padding: 0 rem 0; /* Reduce el padding para pantallas pequeñas */
  }

  .navbar-brand img {
    height: 1.2em; /* Reduce el tamaño del logo para pantallas pequeñas */
  }

  .nav-icon {
    height: 1em; /* Reduce el tamaño de los iconos para pantallas pequeñas */
  }

  .avatar {
    width: 2.2em; /* Reduce el tamaño del avatar para pantallas pequeñas */
    height: 2.2em;
  }

  .footer-navbar {
    padding: 0.25rem 0; /* Reduce el padding del footer-navbar para pantallas pequeñas */
  }

  .footer-navbar .nav-link {
    padding: 0.25rem 0; /* Reduce el padding de los enlaces en el footer-navbar para pantallas pequeñas */
  }

  .footer-navbar .nav-icon {
    height: 1em; /* Reduce el tamaño de los iconos en el footer-navbar para pantallas pequeñas */
  }
}

.notification-container {
  max-height: 210px; /* Limita la altura del contenedor a 3 notificaciones (70px cada una) */
  overflow-y: auto; /* Habilita el desplazamiento vertical si hay más de 3 notificaciones */
  scroll-behavior: smooth; /* Suaviza el desplazamiento */
}
.notification-body {
  overflow: hidden; /* Oculta cualquier contenido que se desborde */
  text-overflow: ellipsis; /* Muestra los puntos suspensivos (...) */
  display: -webkit-box; /* Caja flexible para controlar el número de líneas */
  -webkit-line-clamp: 2; /* Limita el contenido a 2 líneas */
  -webkit-box-orient: vertical; /* Define la orientación vertical del contenido */
  white-space: normal; /* Permite el salto de línea */
  line-height: 1.2em; /* Ajusta la altura de línea para controlar el espacio vertical */
  max-height: 2.4em; /* Limita la altura total para mantener solo 2 líneas visibles */
}

.notification-dropdown {
  position: relative;
}

.notification-icon {
  cursor: pointer;
}

.notification-count {
  color: red;
  padding: 1px 1px;
  position: absolute;
  top: -1px;
  right: -1px;
}

.notification-list {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.notification-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.notification-item h4 {
  margin: 0 0 5px;
}

.notification-item p {
  margin: 0;
  color: #666;
}

.footer-navbar-expanded {
  height: 70px; /* Ajusta la altura según sea necesario */
}
</style>